import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

interface NavLinkProps {
  children?: string | React.ReactNode;
  to: string;
}

function NavLink({ to, children }: NavLinkProps) {
  const router = useRouter();
  const isActive = router.pathname === to;
  const isHome = router.pathname === "/"
  if (isActive && !isHome) {
    return (
      <div className="bg-gray-100 rounded-lg dark:bg-gray-700">
        <Link href={to} legacyBehavior>
          {children}
        </Link>
      </div>
    );
  }

  return (
    <Link href={to} passHref legacyBehavior>
      {children}
    </Link>
  );
}

export default NavLink;
