import { Box, Text } from "@chakra-ui/react";
import CopyToClipboard from "./CopyToClipboard";

export const TextDisplay = ({
  text,
  label,
  currency,
  percentage = false,
  inCents = false,
  errorText = "Sin data",
}: {
  text: string | number | null | undefined | Date | any;
  label: string;
  currency?: string;
  percentage?: boolean;
  inCents?: boolean;
  errorText?: string;
}) => {
  if (!text)
    return (
      <Box>
        <Text fontWeight="bold">{label}</Text>
        <Text fontSize="sm" mr="2">
          {errorText}
        </Text>
      </Box>
    );
  if (percentage && typeof text === "number") {
    return (
      <Box>
        <Text fontWeight="bold">{label}</Text>
        <Text fontSize="sm" mr="2">
          {new Intl.NumberFormat("es", { minimumFractionDigits: 8 }).format(
            text
          )}
          %
        </Text>
      </Box>
    );
  }
  if (text && typeof text === "number") {
    const currencyProps = currency
      ? { style: "currency", currency: currency }
      : {};
    const isInCents = inCents ? text / 100 : text;
    return (
      <Box>
        <Text fontWeight="bold">{label}</Text>
        <Text fontSize="sm" mr="2">
          {new Intl.NumberFormat("es", { ...currencyProps }).format(isInCents)}
        </Text>
      </Box>
    );
  }
  return (
    <Box>
      <Text fontWeight="bold">{label}</Text>
      <CopyToClipboard text={String(text)}>
        <Text fontSize="sm" mr="2">
          {text}
        </Text>
      </CopyToClipboard>
    </Box>
  );
};
