/* eslint-disable @next/next/no-img-element */
import { useUser } from "@auth0/nextjs-auth0";
import { Skeleton, SkeletonCircle } from "@chakra-ui/react";
import {
  AcademicCapIcon,
  CalendarIcon,
  BanknotesIcon,
  ChartBarSquareIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  BuildingLibraryIcon,
  ArrowRightOnRectangleIcon,
  ShoppingBagIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { SettingsModal } from "components/SettingsModal";
import { useState } from "react";
import NavLink from "../ActiveLink";
import ShowIfOpsAdmin, { useIsAdmin } from "../ShowIfOpsAdmin";
import ShowIfSchoolAdmin from "../ShowIfSchoolAdmin";
import { cn } from "../../../lib/utils";
// enum CONCILIATION_SCHOOL_IDS {
//   FOUR_GEEKS_CHL = 3,
//   AMERICAN_SCHOOL_WAY_COL = 68,
//   EAN = 71,
// }

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Bar: React.FC<Props> = ({ children, className }) => {
  const { user, isLoading } = useUser() as any;
  const { isAdmin } = useIsAdmin();
  const [openCreateApplication, setOpenCreateApplication] = useState(false);

  if (isLoading) return <></>;

  const hasConciliationsEnabled = true;
  // const hasConciliationsEnabled = user.schools.some((schools: any) => {
  //   return (
  //     schools.id === CONCILIATION_SCHOOL_IDS.AMERICAN_SCHOOL_WAY_COL ||
  //     schools.id === CONCILIATION_SCHOOL_IDS.FOUR_GEEKS_CHL ||
  //     schools.id === CONCILIATION_SCHOOL_IDS.EAN
  //   );
  // });

  return (
    <div className="flex gap-1 bg-white h-[80%] dark:bg-gray-800">
      {user ? (
        <aside
          className="max-w-[30%] flex justify-between flex-col py-12 max-h-[800px]"
          aria-label="Sidebar"
        >
          <div className="flex justify-center">
            <NavLink to="/">
              <a>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={"/flevo-logo-f-straight.svg"}
                  width={"35px"}
                  height={"auto"}
                  alt="Flevo logo"
                  key={"flevo-logo-f"}
                  className="dark:hidden"
                />
                <img
                  src={"/flevo-logo-f-straight-white.svg"}
                  width={"35px"}
                  height={"auto"}
                  alt="Flevo logo"
                  key={"flevo-logo-f-white"}
                  className="dark:block hidden"
                />
              </a>
            </NavLink>
          </div>
          <div className="overflow-y-auto py-4 px-3  rounded dark:bg-gray-800">
            <ul className="space-y-2">
              <li>
                <NavLink to="/application">
                  <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <ClipboardDocumentListIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Aplicaciones
                    </span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to="/student">
                  <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <AcademicCapIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Estudiantes
                    </span>
                  </a>
                </NavLink>
              </li>
              <ShowIfOpsAdmin>
                {!!hasConciliationsEnabled ? (
                  <li>
                    <NavLink to="/dashboard">
                      <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <ChartBarSquareIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                        <span className="flex-1 ml-3 whitespace-nowrap">
                          Pagos
                        </span>
                        <span className="ml-3 items-center rounded-full bg-gradient-to-r from-emerald-500 to-blue-900 px-2 py-0.5 text-xs text-white">
                          Beta
                        </span>
                      </a>
                    </NavLink>
                  </li>
                ) : null}
                {/*<li>*/}
                {/*  <NavLink to="/dashboard">*/}
                {/*    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">*/}
                {/*      <ChartBarSquareIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />*/}
                {/*      <span className="flex-1 ml-3 whitespace-nowrap">*/}
                {/*        Pagos*/}
                {/*      </span>*/}
                {/*      <span className="ml-3 items-center rounded-full bg-gradient-to-r from-emerald-500 to-blue-900 px-2 py-0.5 text-xs text-white">*/}
                {/*        Beta*/}
                {/*      </span>*/}
                {/*    </a>*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
                <li>
                  <NavLink to="/schools">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                      <BuildingLibraryIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Escuelas
                      </span>
                    </a>
                  </NavLink>
                </li>
              </ShowIfOpsAdmin>
              <li>
                <NavLink to="/schools/checkout">
                  <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <ShoppingBagIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Checkout
                    </span>
                  </a>
                </NavLink>
              </li>
              <ShowIfSchoolAdmin>
                {!!hasConciliationsEnabled ? (
                  <li>
                    <NavLink to="/dashboard">
                      <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <ChartBarSquareIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                        <span className="flex-1 ml-3 whitespace-nowrap">
                          Pagos
                        </span>
                        <span className="ml-3 items-center rounded-full bg-gradient-to-r from-emerald-500 to-blue-900 px-2 py-0.5 text-xs text-white">
                          Beta
                        </span>
                      </a>
                    </NavLink>
                  </li>
                ) : null}
              </ShowIfSchoolAdmin>
              <ShowIfOpsAdmin>
                <li>
                  <NavLink to="/programs">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                      <CalendarIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Programas
                      </span>
                    </a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payment-plans">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ">
                      <BanknotesIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Planes de pago
                      </span>
                    </a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/agreements">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                      <DocumentTextIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Acuerdos
                      </span>
                    </a>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/users">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                      <UserCircleIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Usuarios
                      </span>
                    </a>
                  </NavLink>
                </li>
              </ShowIfOpsAdmin>
              <ShowIfSchoolAdmin>
                <li>
                  <NavLink to="/users">
                    <a className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                      <UserCircleIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Usuarios
                      </span>
                    </a>
                  </NavLink>
                </li>
              </ShowIfSchoolAdmin>
            </ul>
          </div>
          <div className="px-3 flex flex-col gap-2">
            <div>{isAdmin ? null : <SettingsModal />}</div>
            <div>
              {user && (
                // eslint-disable-next-line @next/next/no-html-link-for-pages
                <a
                  href="/api/auth/logout"
                  className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <ArrowRightOnRectangleIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Cerrar sesión
                  </span>
                </a>
              )}
            </div>
          </div>
        </aside>
      ) : (
        <aside className="px-4 min-w-[256px]">
          <SkeletonCircle w="50px" />
          {/* @ts-ignore */}
          <Skeleton w="200px" />
        </aside>
      )}
      <div
        className={cn(
          "px-2 min-w-[70%] h-full min-h-[99vh] py-2 flex-1 bg-gray-50",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
