import { useUser } from "@auth0/nextjs-auth0";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Select,
} from "@chakra-ui/react";
import { CogIcon } from "@heroicons/react/24/outline";
import { SelectWrapper } from "components/shared/Forms";
import { TextDisplay } from "components/shared/TextDisplay";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { getApiKeyService } from "services/school/api-key";

export function SettingsModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiKey, setApiKey] = useState<{ APIKey: string }>({ APIKey: "" });
  const { user } = useUser() as any;
  const { handleSubmit, register } = useForm<any>();

  const onSubmit = async (data: any) => {
    try {
      const response = await getApiKeyService({ id: data.school });
      setApiKey(response);
    } catch (err) {}
  };
  return (
    <div className="flex-1">
      <button
        onClick={onOpen}
        className="flex w-full items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
      >
        <CogIcon className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
        <span className="ml-3 whitespace-nowrap">Configuración</span>
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configuración</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SelectWrapper name="school" label="Escuela">
                <Select
                  placeholder="Seleccione una escuela"
                  {...register("school", {
                    required: "Este campo es requerido",
                  })}
                >
                  {user?.schools?.map(
                    (school: { id: string; country: string; name: string }) => (
                      <option key={school.id} value={school.id}>
                        {school.country} - {school.name}
                      </option>
                    )
                  )}
                </Select>
              </SelectWrapper>
              <Flex flexDir="column" gap={3}>
                {apiKey.APIKey.length !== 0 && (
                  <Alert status="success">
                    <AlertIcon />
                    <Flex flexDir="column">
                      <AlertTitle>Generaste tu API Key con éxito!</AlertTitle>
                      <AlertDescription>
                        Asegúrate de copiarla ahora. No vas a poder verla de
                        nuevo.
                      </AlertDescription>
                    </Flex>
                  </Alert>
                )}
                <Box>
                  <TextDisplay
                    text={apiKey.APIKey}
                    label="Copia tu Api Key"
                    errorText="Si ya tienes una API Key vigente, recuerda que será revocada al generar una nueva."
                  />
                  <Button
                    mt={2}
                    disabled={apiKey.APIKey.length !== 0}
                    type="submit"
                  >
                    Generar Api Key
                  </Button>
                </Box>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
