import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
} from "@chakra-ui/react";

interface InputType extends InputProps {
  name: string;
  label?: string;
  helperText?: string;
  errors?: { [x: string]: any };
}

export default function InputWrapper({
  name,
  label,
  errors = [],
  helperText = "",
  children,
}: InputType): JSX.Element {
  return (
    <>
    {/* @ts-ignore */}
      <FormControl isInvalid={!!errors[name]} mb="4">
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        {children}
        <Box>
          {!errors[name] ? (
            <FormHelperText>{helperText}</FormHelperText>
          ) : (
            <Box maxWidth={240}>
              <FormErrorMessage>{errors[name].message}</FormErrorMessage>
            </Box>
          )}
        </Box>
      </FormControl>
    </>
  );
}
