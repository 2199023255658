import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// genereate space

export function phoneValidations(value: string) {
  if (!value) return;
  if (isPossiblePhoneNumber(value) && isValidPhoneNumber(value)) {
    return true;
  }
  return false;
}

export const getValuesData = (
  fields: string[],
  getValues: (field: string) => any
) => {
  return fields.reduce((acc: any, field: string) => {
    acc[field] = getValues(field);
    return acc;
  }, {});
};

export const currencyFormat = (value: number, currency?: string) => {
  if (!currency)
    return new Intl.NumberFormat("es", {
      minimumFractionDigits: 2,
    }).format((value || 0) / 100);
  return new Intl.NumberFormat("es", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  }).format((value || 0) / 100);
};

export function dateToString(date: Date) {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export function stringToDate(dateString: string) {
  return new Date(dateString + 'T00:00:00');
}

export function formatDateString(dateString: string, format: string = 'dd-MM-yyyy') {
  const date = stringToDate(dateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return format
    .replace('dd', day)
    .replace('MM', month)
    .replace('yyyy', year.toString());
}