import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { Flex, IconButton, useClipboard } from "@chakra-ui/react";
import { ReactElement } from "react";

const CopyToClipboard = ({
  children,
  text = "copy something to the clipboard",
}: {
  text: string;
  children: string | ReactElement;
}) => {
  const { onCopy, hasCopied } = useClipboard(text);

  return (
    <Flex flexDir="row" align="center">
      {children}
      <IconButton
        bg="white"
        aria-label="Copy"
        icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
        size="sm"
        onClick={onCopy}
      />
    </Flex>
  );
};

export default CopyToClipboard;
